import { Service } from './service'

const PATH = 'prompt/'

class PromptService extends Service {
  getLatestPrompts (params) {
    return this.get(PATH + 'latests', params)
  }

  savePrompt (params) {
    return this.post(PATH + 'save', params)
  }
}

export default new PromptService()
