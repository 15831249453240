import AiProfile from '@/components/AiProfile/AiProfile.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'AiProfileAssistant',
  components: { AiProfile },
  props: {
    drawerCtrl: {
      type: Object,
      default: {
        show: false,
      },
    },
  },
  data () {
    return {
      aiProfileStatus: {
        edited: false,
      },
      showDialogConfirmExit: false,
    }
  },
  methods: {
    closeDrawer () {
      if (this.aiProfileStatus.edited) {
        this.showDialogConfirmExit = true
      } else {
        this.drawerCtrl.show = false
      }
    },
    emitSave () {
      EventBus.$emit('AiProfile:save')
      this.showDialogConfirmExit = false
      this.drawerCtrl.show = false
    },
    dontSave () {
      this.drawerCtrl.show = false
      this.showDialogConfirmExit = false
    },
  },
  created () {

  },
}
